import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import trim from 'lodash/trim';

import { LanguageTuple, UseCurrentLangHook } from './model';

export const useCurrentLang: UseCurrentLangHook = (defaultLangPath = '/') => {
  const { pathname } = useLocation();

  const {
    allHomepage: { nodes },
  } = useStaticQuery(graphql`
    {
      allHomepage {
        nodes {
          id
          lang
          url
        }
      }
    }
  `);

  const languages = useMemo(
    (): LanguageTuple[] =>
      nodes.map(({ lang, url }) => [lang, url]).sort(([, a], [, b]) => b.length - a.length),
    [nodes]
  );

  const defaultLang = useMemo(
    (): LanguageTuple | undefined => languages.find(([, url]) => url === defaultLangPath),
    [defaultLangPath, languages]
  );

  if (!defaultLang) {
    throw new Error(`
      Problem, can't find the default language. 
      Try using the parameter, defaultLang to help find main page.
    `);
  }

  const urlLang = pathname.split('/')[1];

  const searchForCurrentLang = useMemo(
    () => languages.find(([, url]) => trim(url, '/') === urlLang),
    [languages, urlLang]
  );

  const currentLang = searchForCurrentLang || defaultLang;

  return { languages, currentLang, defaultLang };
};
